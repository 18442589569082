<template>
  <TTView>
    <TTExample
      file="components/TTChipGroup/examples/TTChipGroupExample"
    />
  </TTView>
</template>

<script>
export default {
  name: 'Chip',
};
</script>
